import { QuestionType, QuestionTypeEnum } from '@netpurpose/api'
import { ColoredCircle, IconWithTooltip, Tooltip } from '@netpurpose/np-ui'
import {
  QuestionTypeIconsContainer,
  QuestionTypeIconWrapper,
} from './QuestionTypeIconsWithTooltips.style'

type Props = {
  isEstimated: boolean | undefined
  isReported: boolean | undefined
  type: QuestionType
}

export const QuestionTypeIconsWithTooltips = ({ isEstimated, isReported, type }: Props) => (
  <QuestionTypeIconsContainer>
    {isEstimated && (
      <QuestionTypeIconWrapper>
        <IconWithTooltip
          icon="ApproxEquals"
          alt="approximately equals"
          color="green"
          tooltip="Estimated"
          /* 19x19 to match line height of table row. */
          width={19}
          height={19}
        />
      </QuestionTypeIconWrapper>
    )}
    {/* Always use only the calculated icon for KPIs. */}
    {isReported && type !== QuestionTypeEnum.KPI && (
      <QuestionTypeIconWrapper>
        <Tooltip title="Reported" placement="right">
          <ColoredCircle color="actionLink" className="reported-icon" size="small" />
        </Tooltip>
      </QuestionTypeIconWrapper>
    )}
    {type === QuestionTypeEnum.KPI && (
      <QuestionTypeIconWrapper>
        <IconWithTooltip
          icon="FunctionFormula"
          alt="formula"
          color="darkBlue"
          tooltip="Standardised"
          width={19}
          height={19}
        />
      </QuestionTypeIconWrapper>
    )}
    {type === QuestionTypeEnum.ANALYSED_KPI && (
      <QuestionTypeIconWrapper>
        <IconWithTooltip
          icon="Analysed"
          alt="analysed"
          color="typePrimary"
          tooltip="Analysed"
          width={19}
          height={19}
        />
      </QuestionTypeIconWrapper>
    )}
  </QuestionTypeIconsContainer>
)
