import { DataDictionaryItem, Question } from '@netpurpose/api'
import { ColoredCircle, Icon } from '@netpurpose/np-ui'
import { ReportedOrEstimatedIconContainer } from './QuestionTypeIcons.style'

type Props = {
  question: DataDictionaryItem | Question
}

export const QuestionTypeIcons = ({ question }: Props) => {
  if (question.type === 'KPI') {
    // This component is usually used in dropdown menus, so we generally don't
    // want it to show a tooltip, hence no alt. Use QuestionTypeIconsWithTooltips instead.
    return <Icon icon="FunctionFormula" alt="" color="darkBlue" />
  }

  const icons = []

  if (question.isReported) {
    icons.push(<ColoredCircle color="actionLink" key="reported" />)
  }
  if (question.isEstimated) {
    icons.push(<Icon icon="ApproxEquals" alt="" color="green" key="estimated" />)
  }

  return <ReportedOrEstimatedIconContainer>{icons}</ReportedOrEstimatedIconContainer>
}
