import { ComponentProps, FC } from 'react'
import { Icon, IconProps } from '../Icon'
import { Tooltip } from '../Tooltip'
import { IconWrapper } from './IconWithTooltip.style'

type Props = {
  tooltip: ComponentProps<typeof Tooltip>['title']
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement']
  overlayStyle?: ComponentProps<typeof Tooltip>['overlayStyle']
  highlightOnHover?: boolean
  onHover?: () => void
  onClose?: () => void
  tooltipOpen?: ComponentProps<typeof Tooltip>['open']
  withoutPadding?: boolean
  minimalPadding?: boolean
  widerWidth?: boolean
} & IconProps

// IconWrapper is required because the ant-tooltip-open class does not attach to the icon's <svg> component.
// We attach it to a wrapper <span> instead.
export const IconWithTooltip: FC<Props> = ({
  tooltip,
  tooltipPlacement,
  highlightOnHover = false,
  onHover,
  onClose,
  overlayStyle,
  tooltipOpen,
  withoutPadding = false,
  minimalPadding = false,
  widerWidth = false,
  ...iconProps
}) => (
  <Tooltip
    placement={tooltipPlacement || 'right'}
    title={tooltip}
    {...(overlayStyle ? { overlayStyle: overlayStyle } : {})}
    onOpenChange={(visible) => {
      // Avoid triggering twice as mouse moves in and out.
      if (visible) {
        onHover?.()
      } else {
        onClose?.()
      }
    }}
    open={tooltipOpen}
    $withoutPadding={withoutPadding}
    $minimalPadding={minimalPadding}
    $widerWidth={widerWidth}
  >
    <IconWrapper $highlightOnHover={highlightOnHover}>
      <Icon hideTooltip {...iconProps} />
    </IconWrapper>
  </Tooltip>
)
