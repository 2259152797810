import { ComponentType } from 'react'
import { DataValue, NOT_FOUND, UNPROCESSABLE_UNIT } from '@netpurpose/types'
import { formatNumber } from '@netpurpose/utils'
import { Monospaced } from '#components/common/dataRendering'

export const getRenderDataValue =
  <T extends DataValue>(
    formatter: (value?: number, unit?: string) => string = formatNumber,
    // @ts-expect-error
    WrapperComponent: ComponentType<{ children: string }> = Monospaced,
  ) =>
  (dataValue: T) => {
    const { unit, value } = dataValue

    let outputText = ''
    if (unit === NOT_FOUND || unit === UNPROCESSABLE_UNIT) {
      outputText = NOT_FOUND
    } else {
      outputText = formatter(value, unit)
    }

    return <WrapperComponent>{outputText}</WrapperComponent>
  }
