import { FC } from 'react'
import { Goal } from '@netpurpose/api'
import { ComparisonSpot } from '@netpurpose/np-ui'
import { isNumber } from '@netpurpose/types'
import { getRenderDataValue } from '#services/dataRendering'
import { formatImpactPerMillion } from '#services/dataRendering/formatters'
import { ImpactComparison, ImpactComparisonDataValues } from '#types/models'
import { Container } from './ComparisonValue.style'

type Props = {
  dataIndex: keyof ImpactComparisonDataValues
  comparisonDataIndex: keyof ImpactComparisonDataValues
  record: ImpactComparison
  goal: Goal | undefined
}

const ComparisonValue: FC<Props> = ({ dataIndex, comparisonDataIndex, record, goal }) => {
  const { displayName } = record

  const datapoint = record[dataIndex]
  const renderDatapoint = getRenderDataValue(formatImpactPerMillion)

  const { value } = datapoint
  const comparisonValue = record[comparisonDataIndex].value

  if (!isNumber(value) || !isNumber(comparisonValue) || value === comparisonValue) {
    return renderDatapoint(datapoint)
  }

  const valueIsGreater = value > comparisonValue
  const tooltipText = `This portfolio contributes ${
    valueIsGreater ? 'more' : 'less'
  } to ${displayName}`

  return (
    <Container>
      {renderDatapoint(datapoint)}
      <ComparisonSpot isIncrease={valueIsGreater} tooltipText={tooltipText} goal={goal} />
    </Container>
  )
}

export default ComparisonValue
