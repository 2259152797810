import styled from 'styled-components'

export const QuestionTypeIconsContainer = styled.div`
  display: flex;
`

export const QuestionTypeIconWrapper = styled.div`
  display: grid;
  grid-template-columns: 19px 1fr;
  grid-column-gap: 4px;
  align-items: center;
  /* 19x19 to match line height of table row. */
  height: 19px;
  width: 19px;

  > :first-child {
    display: block;
    margin: auto;
  }
`
